import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Typography from "@mui/material/Typography";
import { colors } from "../../utils/const";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";

const steps = ["", "", "", "", ""];

export default function HorizontalNonLinearStepper() {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const autoIncrementStep = () => {
    setActiveStep((prevStep) =>
      prevStep === steps.length - 1 ? 0 : prevStep + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(autoIncrementStep, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const contentGen = ({ title, heading, text, img, idx }) => (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: { xlg: "space-around" },
        alignItems: {
          xs: "start",
        },
        color: "white",
        mx: {
          xs: 2,
          md: 4,
        },
        zIndex: 999999,
      }}
      id="instant-approval"
    >
      <Box
        data-aos="fade-up"
        data-aos-delay="100"
        sx={{
          width: {
            xs: "100%",
            lg: "90%",
            md: "80%",
            sm: "100%",
          },
        }}
      >
        <Box
          sx={{
            mb:
              idx == 0
                ? { xs: 36, md: 40 }
                : idx == 1
                ? { xs: 26, md: 28 }
                : idx == 2
                ? { xs: 16, md: 18 }
                : idx == 3
                ? { xs: 6, md: 8 }
                : idx == 4
                ? -2
                : 0,
            mt:
              idx == 0
                ? { xs: 4, sm: 0, lg: 5 }
                : idx == 1
                ? { xs: 14, sm: 10, md: 12, lg: 17 }
                : idx == 2
                ? { xs: 24, sm: 20, md: 22, lg: 27 }
                : idx == 3
                ? locale === "en"
                  ? { xs: 31, sm: 30, md: 32, lg: 37 }
                  : { xs: 34, sm: 30, md: 32, lg: 37 }
                : idx == 4
                ? { xs: 42, sm: 38, md: 42, lg: 47 }
                : 0,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "20px", sm: "20px", md: "25px", lg: "30px" },
              mb: { md: 1 },
            }}
          >
            {heading}
          </Typography>
          <Typography
            variant="body"
            sx={{
              fontSize: { xs: "10px", sm: "12px", md: "16px" },
            }}
          >
            {text}
          </Typography>
        </Box>
        <Box
          sx={{
            mr: locale === "ar" ? { xs: -6, md: -8 } : "",
            ml: locale === "en" ? { xs: -6, md: -8 } : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Box
              sx={{
                mb: 2,
                marginTop: 7,
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=eg.contact"
                target="_blank"
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: "80px", md: "100px", lg: "140px" },
                    height: { xs: "30px", md: "35px", lg: "45px" },
                    cursor: "pointer",
                    // aspectRatio: "auto",
                    mt: {
                      xs: 1,
                      md: 1,
                    },
                    "@media (max-width: 600px)": {
                      aspectRatio: "auto", // Apply aspect ratio fix only for mobile
                      height: "auto", // Allow the height to adjust dynamically
                    },
                  }}
                  src={
                    t === en
                      ? "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/home-google-play.png"
                      : "/googleplay-ar.svg"
                  }
                  alt="google-play"
                  loading="lazy"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/contactnow-%D9%83%D9%88%D9%86%D8%AA%D9%83%D8%AA/id1544159088"
                target="_blank"
              >
                <Box
                  component="img"
                  sx={{
                    width: { xs: "80px", md: "100px", lg: "140px" },
                    height: { xs: "30px", md: "35px", lg: "45px" },
                    cursor: "pointer",
                    // aspectRatio: "auto",
                    mx: {
                      xs: 1,
                      md: 2,
                    },
                    mt: {
                      xs: 1,
                      md: 1,
                    },
                    "@media (max-width: 600px)": {
                      aspectRatio: "auto", // Apply aspect ratio fix only for mobile
                      height: "auto", // Allow the height to adjust dynamically
                    },
                  }}
                  src={
                    t === en
                      ? "https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/home-app-store.png"
                      : "/appstore-ar.svg"
                  }
                  alt="home-app-store"
                  loading="lazy"
                />
              </a>
            </Box>

            <Box>
              <Box
                component="img"
                sx={{
                  width: { xs: "50px", md: "60px", lg: "100px" },
                  height: { xs: "50px", md: "60px", lg: "100px" },
                }}
                src="/qr/contact.png"
                loading="lazy"
                alt="qrcode"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        sx={{
          margin: {
            md: "inherit",
            xs: "auto",
          },
          width: {
            xs: "200px",
            sm: "250px",
            md: "275px",
            xl: "300px",
          },
          height: {
            sm: "400px",
            md: "450px",
            lg: "500px",
            xl: "600px",
          },
          display: {
            xs: "none",
            sm: "block",
          },
        }}
        src={img}
        alt="steps"
        loading="lazy"
      />
    </Box>
  );

  const extractContent = (idx) => {
    return contentGen({
      title: t.stepsFeature[idx].subTitle,
      heading: t.stepsFeature[idx].title,
      text: t.stepsFeature[idx].text,
      img: t.stepsFeature[idx].img,
      idx,
    });
  };

  return (
    <>
      <Box
        className="stepper-box"
        sx={{
          backgroundColor: colors.blue,
          position: "relative",
          py: 4,
          height: { xs: "200px", sm: "500px", md: "500px", lg: "700px" },
          color: "white",
          height: { xs: "200px", sm: "350px", md: "500px", lg: "700px" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          gap: 6,
          position: "relative",
          py: { xs: 35, sm: 20 },
          overflow: "hidden",
          height: { xs: "200px", sm: "500px", md: "600px", lg: "700px" },
          zIndex: "1",
        }}
      >
        <Box
          sx={{
            right: {
              xs: 0,
              md: locale === "en" ? 0 : "10%",
            },
            left: {
              xs: 0,
              md: locale === "en" ? "10%" : 0,
            },
            top: {
              xs: "5%",
              md: "20%",
            },
            display: "flex",
            mt: { xs: -30, sm: 0 },
            px: { xs: 2, md: 8 },
            zIndex: 999,
            width: "100%",
          }}
        >
          <Box
            alt="contact now logo"
            component="img"
            src="https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/contact-now.png"
            sx={{
              maxWidth: { xs: "60px", lg: "80px" },
              display: { xs: "block", sm: "none", lg: "block" },
              mt: 3,
              mb: 5,
            }}
            style={{
              position: "absolute",
              top: "2px",
            }}
          ></Box>
          <Stepper
            sx={{
              display: "block",
              zIndex: 999999,
              pt: { xs: 4, sm: 0, lg: 6 },
            }}
            nonLinear
            activeStep={activeStep}
            orientation={"vertical"}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepButton
                  color="inherit"
                  sx={{
                    py: { xs: 0.5, md: 1 },
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={handleStep(index)}
                  aria-label={`step ${label}`}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          {extractContent(activeStep)}
        </Box>
        <Box
          sx={{
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(https://image-solution-no-scale.s3.us-east-2.amazonaws.com/upload/home-line.png)`,
            alt: "home-line",
            backgroundRepeat: "no-repeat",
            transform: `scaleX(${locale === "en" ? -1 : 1})`,
            zIndex: 1,
          }}
          loading="lazy"
        />
      </Box>
    </>
  );
}
